<template lang='pug'>
  .awesome-tab
    .awesome-tab-nav(:class='customClassNav')
      .awesome-tab-nav__item(
        v-for='tab in tabs'
        :key='tab.name'
        :class='[{ "selected": tab.name === selectedTab }, customClassNavItem]'
        @click='clickOnTab(tab.name)'
      )
        span {{ tabLabel(tab.label, tab.data) }}
        // слот для иконки рядом с табом
        slot(name='icon')
    .awesome-tab-content
      // слот для контента
      slot
</template>

<script>
export default {
  name: 'AwesomeTabs',
  props: {
    /**
     * Универсальный компонент табов.
     *
     * Принимающие параметры компонента.
     * @param { tabs } Array Массив самих табов и возможных данных.
     * @param { selectedTab } String Текущий выбраннный таб.
     * @param { isDataVisible } Boolean Показывать данные или нет.
     * @param { customClassNav } String Дополнительные стили для класса awesome-tab-nav.
     * @param { customClassNavItem } String Дополнительные стили для класса awesome-tab-nav__item.
     */
    tabs: {
      type: Array,
      required: true,
    },
    selectedTab: {
      type: String,
      required: true,
    },
    isDataVisible: {
      type: Boolean,
      default: false,
    },
    customClassNav: {
      type: String,
      default: '',
    },
    customClassNavItem: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  methods: {
    tabLabel(tabLabel, tabData) {
      return this.isDataVisible ? tabLabel + ' ' + tabData : tabLabel;
    },
    clickOnTab(tabName) {
      this.$emit('changeTab', tabName);
    },
  },
};
</script>

<style lang='scss' scoped>
.awesome-tab {
  width: 100%;
  display: flex;
  flex-direction: column;

  &-nav {
    display: flex;
    align-items: center;
    background: $blue50;
    border-radius: 10px;
    height: 40px;
    max-width: max-content;

    &__item {
      padding: 11px 12px;
      line-height: 18px;
      font-size: 14px;
      font-weight: 400;
      color: #7486B3;

      &:hover {
        cursor: pointer;
        color: $blue600;
        background: $blue100;
      }

      &.selected {
        color: $blue600;
        background: $blue100;
        font-weight: 500;
      }
    }

    &__item:first-child {
      border-radius: 10px 0 0 10px;
    }

    &__item:last-child {
      border-radius: 0 10px 10px 0;
    }
  }

  &-content {
    margin-top: 16px;
  }
}
</style>
